<template>
    <!-- WARNING this file is generated edits made will be lost on next generation -->
    <div>
        <NavBar></NavBar>
        <div class="container-fluid">
            <b-row>
            <!-- Sidebar -->
            <TOCChapter chapter-id="Chap20Arrays"></TOCChapter>
				<b-col ></b-col>
                <!-- Main Content -->
                <b-col role="main" md="6" >
					<ChapterHeading chapter-title="Chapter 20: Arrays" image-name="array.jpg" image-alt="Arrays"></ChapterHeading>
                    <!-- Contents BEGIN -->
                    <div id="what-will-you-learn-in-this-chapter" class="anchor"></div>
<h1 data-number="1"><span class="header-section-number">1</span> What will you learn in this chapter? <a href="#what-will-you-learn-in-this-chapter"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<ul>
<li><p>What is an array?</p></li>
<li><p>How to create an array.</p></li>
<li><p>How to create multidimensional arrays.</p></li>
<li><p>How to iterate over an array.</p></li>
<li><p>How to find an element in an array.</p></li>
</ul>
<div id="technical-concepts-covered" class="anchor"></div>
<h1 data-number="2"><span class="header-section-number">2</span> Technical concepts covered <a href="#technical-concepts-covered"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<ul>
<li><p>array</p></li>
<li><p>element type</p></li>
<li><p>length</p></li>
<li><p>capacity</p></li>
<li><p>dimension of arrays</p></li>
</ul>
<div id="definition" class="anchor"></div>
<h1 data-number="3"><span class="header-section-number">3</span> Definition <a href="#definition"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<ul>
<li><p>An array is a <strong><u>collection</u></strong> of elements of the <u>same type</u>.</p></li>
<li><p>The type of elements inside an array is called the <u>Element Type</u></p></li>
<li><p>This collection has a <strong>fixed number of elementsknown at compile time</strong>.</p></li>
<li><p>The</p></li>
</ul>
<div id="usage-example" class="anchor"></div>
<BuyCopyInvite></BuyCopyInvite>
<h1 data-number="4"><span class="header-section-number">4</span> Usage example <a href="#usage-example"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>Let’s say that you want to store month’s name. You can use an array of strings. The number of months is known at compile time (12)</p>
<div id="array-creation" class="anchor"></div>
<h1 data-number="5"><span class="header-section-number">5</span> Array creation <a href="#array-creation"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>Here is an example of how to define an array in a go program :</p>
<pre v-highlightjs><code class="go" v-pre >// array/creation/main.go
package main

import &quot;fmt&quot;

func main() {
    var myArray [2]int
    myArray[0] = 156
    myArray[1] = 147
    fmt.Println(myArray)
    // output : [156 147]
}</code></pre>
<p>The previous program define at the first line a variable called <span v-highlightjs><code class="go" v-pre style="display: inline">myArray</code></span> which is an array of integers (<span v-highlightjs><code class="go" v-pre style="display: inline">int</code></span>) and that has a length of 2.</p>
<p>In other words, this is a collection of two elements of type <span v-highlightjs><code class="go" v-pre style="display: inline">int</code></span>.</p>
<p>We then set the first element with the expression <span v-highlightjs><code class="go" v-pre style="display: inline">myArray[0]</code></span>. <strong>0</strong> represents the <strong>index</strong> of the first element of <span v-highlightjs><code class="go" v-pre style="display: inline">myArray</code></span>. The elements are indexed from 0.</p>
<p>After that, we attribute to the second element of the array the value 147. An then, we print our array.</p>
<figure>
<b-img :src="require('@/assets/images/array_index_length.png')" alt="Array : index and length[fig:Array-index-length]"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">Array : index and length<span id="fig:Array-index-length" label="fig:Array-index-length">[fig:Array-index-length]</span></figcaption>
</figure>
<div id="a-more-concise-way" class="anchor"></div>
<h2 data-number="5.1"><span class="header-section-number">5.1</span> A more concise way <a href="#a-more-concise-way"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>Using the previous method to define an array is correct but not very convenient to write. We used three lines to define the array. There is an easier method: using an <strong>array literal</strong>.</p>
<pre v-highlightjs><code class="go" v-pre >myArray := [2]int{156,147}
fmt.Println(myArray)
// output : [156 147]</code></pre>
<p>In the previous lines of code, we define an array of 2 integers and directly populate it by using the array literal <span v-highlightjs><code class="go" v-pre style="display: inline">[n]T{}</code></span></p>
<p>You can also let the compiler <strong>infer the size of our array</strong> by using the syntax <span v-highlightjs><code class="go" v-pre style="display: inline">[...]T{}</code></span>. For instance :</p>
<pre v-highlightjs><code class="go" v-pre >myArray := [...]int{156, 147}</code></pre>
<div id="summary" class="anchor"></div>
<h2 data-number="5.2"><span class="header-section-number">5.2</span> Summary <a href="#summary"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<pre v-highlightjs><code class="go" v-pre >// long way
var a [2]int
a[0] = 156
a[1] = 147
fmt.Println(a)

// more concise
b := [2]string{&quot;FR&quot;, &quot;US&quot;}
fmt.Println(b)

// size computed by the compiler
c := [...]float64{13.2, 37.2}
fmt.Println(c)

// values not set (yet)
d := [2]int{}
fmt.Println(d)</code></pre>
<div id="zero-value" class="anchor"></div>
<h1 data-number="6"><span class="header-section-number">6</span> Zero value <a href="#zero-value"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>When you define an array, you do not have to know all the values you want to put in it.</p>
<p>You can create an empty array and fill it during the program execution. <strong>But remember there are no undefined elements in Go</strong>.</p>
<p>When you define an empty array go will fill it with the zero value of the element type:</p>
<pre v-highlightjs><code class="go" v-pre >// array/zero-value/main.go
package main

import &quot;log&quot;

func main() {
    var myA [3]int
    log.Printf(&quot;%v&quot;, myA)
}</code></pre>
<p>Will output :</p>
<pre v-highlightjs><code class="go" v-pre >2021/01/27 17:56:21 [0 0 0]</code></pre>
<p>We define here an array of integers with a length of 3. Because we do not specify any value inside the curly brackets go fills it with the zero value of integers, which is “0”. Note that the behavior is the same if you define an array of strings without specifying the values :</p>
<pre v-highlightjs><code class="go" v-pre >myEmptyArray2 := [2]string{}
fmt.Println(myEmptyArray2)
// output : []</code></pre>
<p>Go will put an empty string into the first element of the collection <span v-highlightjs><code class="go" v-pre style="display: inline">myEmptyArray2[0]</code></span>. and in the second element of the collection <span v-highlightjs><code class="go" v-pre style="display: inline">myEmptyArray2[1]</code></span><strong>.</strong></p>
<div id="built-in-functions" class="anchor"></div>
<h1 data-number="7"><span class="header-section-number">7</span> Built-in functions <a href="#built-in-functions"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<div id="len-length" class="anchor"></div>
<h2 data-number="7.1"><span class="header-section-number">7.1</span> len: length <a href="#len-length"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p><span v-highlightjs><code class="go" v-pre style="display: inline">len</code></span> is a Go builtin function that will return the <strong>length</strong> of a variable v. The length of an array is the number of elements inside it. Let’s denote v an array of type T then the length is :</p>
<pre v-highlightjs><code class="go" v-pre >len(v)</code></pre>
<div id="cap-capacity" class="anchor"></div>
<h2 data-number="7.2"><span class="header-section-number">7.2</span> cap: capacity <a href="#cap-capacity"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>The <span v-highlightjs><code class="go" v-pre style="display: inline">cap</code></span> built-in function returns the same as the <span v-highlightjs><code class="go" v-pre style="display: inline">len</code></span> function. It is not useful in the context of arrays but will be very convenient when manipulating slices.</p>
<div id="access-elements-of-an-array" class="anchor"></div>
<BuyCopyInvite></BuyCopyInvite>
<h1 data-number="8"><span class="header-section-number">8</span> Access elements of an array <a href="#access-elements-of-an-array"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>Arrays are a convenient and efficient way to store data with a length known at compile time. But if we store something, it’s for reading it later. When you want to read a particular element of an array, you can access it directly by its index :</p>
<pre v-highlightjs><code class="go" v-pre >// array/access-element/main.go
package main

import &quot;fmt&quot;

func main() {
    b := [2]string{&quot;FR&quot;, &quot;US&quot;}
    firstElement := b[0]
    secondElement := b[1]
    fmt.Println(firstElement, secondElement)
}</code></pre>
<p>In this example, we access the second element of the array <span v-highlightjs><code class="go" v-pre style="display: inline">b</code></span> by using the notation <span v-highlightjs><code class="go" v-pre style="display: inline">b[1]</code></span><strong>.</strong></p>
<div id="index-out-of-bonds" class="anchor"></div>
<h2 data-number="8.1"><span class="header-section-number">8.1</span> Index out of bonds <a href="#index-out-of-bonds"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>You have to be sure that the index you provide (here 1) is defined in the array. If you want to get the 101st element of the array you will not be able to compile your program. You are trying to access the value of something that does not exist. Here is the compiler gives the error:</p>
<pre v-highlightjs><code class="go" v-pre >invalid array index 100 (out of bounds for 2-element array)</code></pre>
<div id="iterating-over-an-array" class="anchor"></div>
<h1 data-number="9"><span class="header-section-number">9</span> Iterating over an array <a href="#iterating-over-an-array"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<div id="for-loop-with-a-range-clausesubsecfor-loop-with-range-array" class="anchor"></div>
<h2 data-number="9.1"><span class="header-section-number">9.1</span> For loop with a range clause<span id="subsec:For-loop-with-range-array" label="subsec:For-loop-with-range-array">[subsec:For-loop-with-range-array]</span> <a href="#for-loop-with-a-range-clausesubsecfor-loop-with-range-array"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>The for loop allow you to scan all the elements of an array iteratively :</p>
<pre v-highlightjs><code class="go" v-pre >myArray := [2]int{156, 147}
for index, element := range myArray {
    fmt.Printf(&quot;element at index %d is %d\n&quot;, index, element)
}
// output :
//element at index 0 is 156
//element at index 1 is 147</code></pre>
<p>The <span v-highlightjs><code class="go" v-pre style="display: inline">for</code></span> loop in combination with the keyword <span v-highlightjs><code class="go" v-pre style="display: inline">range</code></span> is used to iterate over the elements of an array.</p>
<p>You will get the current index at each iteration with the following syntax.<span v-highlightjs><code class="go" v-pre style="display: inline">i</code></span> and the element <span v-highlightjs><code class="go" v-pre style="display: inline">e</code></span>.</p>
<div id="ignore-the-index-or-the-value" class="anchor"></div>
<h4 data-number="9.1.0.1"><span class="header-section-number">9.1.0.1</span> Ignore the index (or the value) <a href="#ignore-the-index-or-the-value"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h4>
<p>Sometimes you are just interested in the value, not the indexes. You can use the following syntax to ignore the index :</p>
<pre v-highlightjs><code class="go" v-pre >for _, element := range myArray {
    fmt.Println(element)
}</code></pre>
<p><span v-highlightjs><code class="go" v-pre style="display: inline">_</code></span> is the blank identifier. Thanks to this identifier, the index is ignored by the program. Note that you can do the same for the value :</p>
<pre v-highlightjs><code class="go" v-pre >for index, _ := range myArray {
    fmt.Println(index)
}</code></pre>
<p>This use case is rare.</p>
<div id="for-loop" class="anchor"></div>
<h2 data-number="9.2"><span class="header-section-number">9.2</span> For loop <a href="#for-loop"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>The previous method is interesting if you want to start from the beginning iteration. But what if you want to start from a specific index and stops at another one. Let’s say I have a table with five elements, and I want to start from the element at index two and stop at the element at index 4 (the fifth element)?</p>
<p>We can use the for loop with a variable that will serve as a counter.</p>
<pre v-highlightjs><code class="go" v-pre >myArray2 := [2]int{156, 147}
for i := 0; i &lt; len(myArray2); i++ {
    fmt.Printf(&quot;element at index %d is %d\n&quot;, i, myArray2[i])
}
// output :
//element at index 0 is 156
//element at index 1 is 147</code></pre>
<p>Here is the syntax definition :</p>
<p>To iterate over the array from index 0 to the last index, use the following syntax :</p>
<pre v-highlightjs><code class="go" v-pre >for i := 0; i &lt; len(a); i++ {
    fmt.Println(i, a[i])
}</code></pre>
<p>To iterate in descending order (from the last element till to the first) :</p>
<pre v-highlightjs><code class="go" v-pre >for i := len(a) - 1; i &gt;= 0; i-- {
    fmt.Println(i, a[i])
}</code></pre>
<div id="how-to-find-an-element-inside-an-array-with-a-for-loop" class="anchor"></div>
<h1 data-number="10"><span class="header-section-number">10</span> How to find an element inside an array with a for loop? <a href="#how-to-find-an-element-inside-an-array-with-a-for-loop"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>To find an element inside an array, you have no other choice to check every element of it :</p>
<pre v-highlightjs><code class="go" v-pre >// getIndex will find an element (needle) inside an array (haystack)
// if not found the function returns -1
func getIndex(haystack [10]int, needle int) int {
    for index, element := range haystack {
        if element == needle {
            return index
        }
    }
    return -1
}</code></pre>
<p>This function is not perfect. Imagine that the element you search is at the end of the array. Your function will iterate through each array’s element before finding the index.</p>
<p>Another drawback: this function is specific for arrays of 10 elements!</p>
<div id="comparison" class="anchor"></div>
<h1 data-number="11"><span class="header-section-number">11</span> Comparison <a href="#comparison"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>You can compare two arrays that have :</p>
<ul>
<li><p>exactly the <strong>same type</strong> of element (for instance, integers)</p></li>
<li><p>exactly the <strong>same length</strong>.</p></li>
</ul>
<p>And the only comparison operators that are authorized for arrays are <span v-highlightjs><code class="go" v-pre style="display: inline">==</code></span> (equal) and <span v-highlightjs><code class="go" v-pre style="display: inline">!=</code></span> (not equal) :</p>
<pre v-highlightjs><code class="go" v-pre >a := [2]int{1, 2}
b := [2]int{1, 2}
if a == b {
    print(&quot;equal&quot;)
} else {
    print(&quot;not equal&quot;)
}

// output : equal</code></pre>
<div id="how-to-pass-arrays-to-functions" class="anchor"></div>
<BuyCopyInvite></BuyCopyInvite>
<h1 data-number="12"><span class="header-section-number">12</span> How to pass arrays to functions? <a href="#how-to-pass-arrays-to-functions"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<ul>
<li><p>This is an easy mistake: passing an array to a function will copy the array.</p></li>
<li><p>When you want to modify the array in the function pass <strong>a pointer</strong> to the array</p></li>
<li><p>Attention! If your array is <strong>large</strong> making copies of it can impact the performance of your program.</p></li>
</ul>
<div id="demonstration" class="anchor"></div>
<h4 data-number="12.0.0.1"><span class="header-section-number">12.0.0.1</span> Demonstration <a href="#demonstration"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h4>
<p>Here is an example package :</p>
<pre v-highlightjs><code class="go" v-pre >// array/demo/demo.go
package demo

const NewValue = &quot;changedValue&quot;

func UpdateArray1(array [2]string) {
    array[0] = NewValue
}</code></pre>
<p>The function <span v-highlightjs><code class="go" v-pre style="display: inline">UpdateArray1</code></span> takes a parameter of type <span v-highlightjs><code class="go" v-pre style="display: inline">[2]string</code></span>. The function will modify the value at index 0. Let’s test this :</p>
<pre v-highlightjs><code class="go" v-pre >// array/demo/demo_test.go
package demo

import (
    &quot;testing&quot;

    &quot;github.com/stretchr/testify/assert&quot;
)

func TestUpdateArray1(t *testing.T) {
    testArray := [2]string{&quot;Value1&quot;, &quot;Value2&quot;}
    UpdateArray1(testArray)
    assert.Equal(t, NewValue, testArray[0])
}</code></pre>
<p>In this unit test we create a new array of length 2 <span v-highlightjs><code class="go" v-pre style="display: inline">testArray</code></span>.</p>
<p>We launch the function <span v-highlightjs><code class="go" v-pre style="display: inline">UpdateArray1</code></span>. After the function call, we verify that the element at index 0 is equal to the constant <span v-highlightjs><code class="go" v-pre style="display: inline">NewValue</code></span>. Let’s run this test :</p>
<pre v-highlightjs><code class="go" v-pre >$ go test ./...
--- FAIL: TestUpdateArray1 (0.00s)
    demo_test.go:11:
                Error Trace:    demo_test.go:11
                Error:          Not equal:
                                expected: &quot;changedValue&quot;
                                actual  : &quot;Value1&quot;

                                Diff:
                                --- Expected
                                +++ Actual
                                @@ -1 +1 @@
                                -changedValue
                                +Value1
                Test:           TestUpdateArray1
FAIL
FAIL    maximilien-andile.com/array/copy/demo   0.016s
FAIL</code></pre>
<p>The function UpdateArray1 will receive a copy <span v-highlightjs><code class="go" v-pre style="display: inline">testArray</code></span>. The function modifies the copy, not the original array.</p>
<div id="solution-pointer" class="anchor"></div>
<h4 data-number="12.0.0.2"><span class="header-section-number">12.0.0.2</span> Solution: pointer <a href="#solution-pointer"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h4>
<p>To modify the original array, the function should take an element of type<span v-highlightjs><code class="go" v-pre style="display: inline">*[2]string</code></span>. This is a pointer type. It signals that the function can take any pointer to values of type <span v-highlightjs><code class="go" v-pre style="display: inline">[2]string</code></span> (which is called the <strong>base type</strong>). A pointer is an address that points to a space in memory.</p>
<pre v-highlightjs><code class="go" v-pre >// array/demo/demo.go 
package demo

const NewValue = &quot;changedValue&quot;

func UpdateArray2(array *[2]string) {
    array[0] = NewValue
}</code></pre>
<p>This is the second version of the function that now accepts a pointer type. Let’s test that this function is working :</p>
<pre v-highlightjs><code class="go" v-pre >// array/demo/demo_test.go 
package demo

import (
    &quot;testing&quot;

    &quot;github.com/stretchr/testify/assert&quot;
)

func TestUpdateArray2(t *testing.T) {
    testArray := [2]string{&quot;Value1&quot;, &quot;Value2&quot;}
    UpdateArray2(&amp;testArray)
    assert.Equal(t, NewValue, testArray[0])
}</code></pre>
<p>We create a new array of strings <span v-highlightjs><code class="go" v-pre style="display: inline">testArray</code></span>. We initialize the two array values. After the function call, we test that the element at index 0 is equal to the constant <span v-highlightjs><code class="go" v-pre style="display: inline">NewValue</code></span>. Let’s run the test :</p>
<pre v-highlightjs><code class="go" v-pre >$ go test ./...
ok      maximilien-andile.com/array/copy/demo   0.015s</code></pre>
<div id="how-to-copy-an-array" class="anchor"></div>
<h1 data-number="13"><span class="header-section-number">13</span> How to copy an array? <a href="#how-to-copy-an-array"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>What will be the result of this unit test?</p>
<pre v-highlightjs><code class="go" v-pre >// array/demo/demo_test.go 
func TestArrayCopy(t *testing.T) {
    testArray := [2]string{&quot;Value1&quot;, &quot;Value2&quot;}
    newCopy := testArray
    testArray[1] = &quot;updated&quot;
    assert.Equal(t, &quot;updated&quot;, newCopy[1])
}</code></pre>
<p>This test does not pass. The value of <span v-highlightjs><code class="go" v-pre style="display: inline">newCopy[1]</code></span> is equal to <span v-highlightjs><code class="go" v-pre style="display: inline">"Value 2"</code></span>. Why? That’s because when we create the variable <span v-highlightjs><code class="go" v-pre style="display: inline">newCopy</code></span> the array <span v-highlightjs><code class="go" v-pre style="display: inline">testArray</code></span> will be copied. In memory, we will have two separate arrays. When you modify <span v-highlightjs><code class="go" v-pre style="display: inline">testArray</code></span> you do not modify <span v-highlightjs><code class="go" v-pre style="display: inline">newCopy</code></span>.</p>
<div id="how-to-take-the-address-of-an-array" class="anchor"></div>
<h1 data-number="14"><span class="header-section-number">14</span> How to take the address of an array? <a href="#how-to-take-the-address-of-an-array"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>In the following test, we do not copy the array, but we create a new variable of type <span v-highlightjs><code class="go" v-pre style="display: inline">*[2]string</code></span> (pointer to an array of 2 strings) :</p>
<pre v-highlightjs><code class="go" v-pre >// array/demo/demo_test.go 
func TestArrayReference(t *testing.T) {
    testArray := [2]string{&quot;Value1&quot;, &quot;Value2&quot;}
    reference := &amp;testArray
    testArray[1] = &quot;updated&quot;
    assert.Equal(t, &quot;updated&quot;, reference[1])
}</code></pre>
<p><span v-highlightjs><code class="go" v-pre style="display: inline">reference</code></span> is a pointer to the original array <span v-highlightjs><code class="go" v-pre style="display: inline">testArray</code></span>. When we write <span v-highlightjs><code class="go" v-pre style="display: inline">reference[1]</code></span> internally Go will fetch the value stored into <span v-highlightjs><code class="go" v-pre style="display: inline">testArray[1]</code></span>.</p>
<div id="two-dimensional-arrays" class="anchor"></div>
<h1 data-number="15"><span class="header-section-number">15</span> Two-dimensional arrays <a href="#two-dimensional-arrays"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>The term “dimension” might generate anxiety in those who hate mathematics. You should not be afraid; the concept behind is very simple. To understand it, I recommend visualizing it. On figure <a href="#fig:Array-of-one" data-reference-type="ref" data-reference="fig:Array-of-one">2</a> you can see a one dimension array (length is 3, and the elements are of type int).</p>
<figure>
<b-img :src="require('@/assets/images/one_dimension_array.png')" alt="Array of one dimension[fig:Array-of-one]"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">Array of one dimension<span id="fig:Array-of-one" label="fig:Array-of-one">[fig:Array-of-one]</span></figcaption>
</figure>
<p>In the previous example, we stored integers into our array. When you store strings, integers, floats, booleans, bytes, complex numbers into an array, the dimension of this array is one.</p>
<p>When the values of our array is another array, we have a multidimensional array. You can see a two-dimensional array in figure <a href="#fig:2-dimensions-array" data-reference-type="ref" data-reference="fig:2-dimensions-array">3</a>.</p>
<figure>
<b-img :src="require('@/assets/images/2_dimension_array.png')" alt="2 dimensions array[fig:2-dimensions-array]"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">2 dimensions array<span id="fig:2-dimensions-array" label="fig:2-dimensions-array">[fig:2-dimensions-array]</span></figcaption>
</figure>
<p>The type of such an array is :</p>
<pre v-highlightjs><code class="go" v-pre >[3][2]int</code></pre>
<p>The array is composed of 3 values. Those three values are of type <span v-highlightjs><code class="go" v-pre style="display: inline">[2]int</code></span>. This is an array into an array.</p>
<p>To access a particular element in such an array, you can use the following syntax :</p>
<pre v-highlightjs><code class="go" v-pre >value := a[2][1]</code></pre>
<p><span v-highlightjs><code class="go" v-pre style="display: inline">myArr[2]</code></span> will fetch the value at index 2. The value at index 2 is an array. <span v-highlightjs><code class="go" v-pre style="display: inline">[1]</code></span> will fetch the element at index 1 in this array.</p>
<figure>
<b-img :src="require('@/assets/images/target_element_2d_array.png')" alt="2 dimensions array[fig:2-dimensions-array-1]"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">2 dimensions array<span id="fig:2-dimensions-array-1" label="fig:2-dimensions-array-1">[fig:2-dimensions-array-1]</span></figcaption>
</figure>
<div id="example-usage-the-hotel-guest-list" class="anchor"></div>
<h4 data-number="15.0.0.1"><span class="header-section-number">15.0.0.1</span> Example usage: the hotel guest list <a href="#example-usage-the-hotel-guest-list"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h4>
<p>In our hotel, the staff needs to have the ability to get the list of guests. Our hotel is composed of 10 rooms, and each room can have a maximum of two guests. To represent this guest list, we can use a two-dimensional array.</p>
<figure>
<b-img :src="require('@/assets/images/guest_list_2-dim_array.png')" alt="Usage example 2 dimensional array[fig:Usage-example-2-dim-array]"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">Usage example 2 dimensional array<span id="fig:Usage-example-2-dim-array" label="fig:Usage-example-2-dim-array">[fig:Usage-example-2-dim-array]</span></figcaption>
</figure>
<p>The index of the first array is the room number. There are ten rooms, so the length of this array is 10. Inside each element, we put another array composed of two strings (<span v-highlightjs><code class="go" v-pre style="display: inline">[2]string</code></span>). Here is the code that will generate this list :</p>
<pre v-highlightjs><code class="go" v-pre >// array/two-dimensional/guestList/guestList.go
package guestList

import (
    &quot;github.com/Pallinder/go-randomdata&quot;
)

func generate() [10][2]string {
    guestList := [10][2]string{}
    for index, _ := range guestList {
        guestList[index] = roomGuests(index)
    }
    return guestList
}

func roomGuests(roomId int) [2]string {
    guests := [2]string{}
    guests[0] = randomdata.SillyName()
    guests[1] = randomdata.SillyName()
    return guests
}</code></pre>
<p>In the function <span v-highlightjs><code class="go" v-pre style="display: inline">generate</code></span> we will initialize our two-dimensional array. Then we iterate over it with a for-range loop. For each element, we put an element of type <span v-highlightjs><code class="go" v-pre style="display: inline">[2]string</code></span> (An array of length 2 composed of two strings). This array contains the two guest names.<span v-highlightjs><code class="go" v-pre style="display: inline">randomdata.SillyName()</code></span> is used to generate fun names. In a real-life system, we will fetch those names in a database.</p>
<p>If we print this array, we get the following result :</p>
<pre v-highlightjs><code class="go" v-pre >[[Cockatoorogue Liftersolstice] [Footclever Chargernickel] [Chestsatin Scarivy] [Jesterbush Cloaksteel] [Robincanyon Boltchip] [Bonefrill Shiftshy] [Skinnerflannel Looncalico] [Loonnova Spikemesquite] [Hideforest Yakstitch] [Cloakcoconut Minnowsnapdragon]]</code></pre>
<p>To access the name of the second guest sleeping in room 7, we can use the syntax: <span v-highlightjs><code class="go" v-pre style="display: inline">guestList[7][1]</code></span>.</p>
<div id="multi-dimensional-arrays" class="anchor"></div>
<BuyCopyInvite></BuyCopyInvite>
<h1 data-number="16"><span class="header-section-number">16</span> Multi-dimensional arrays <a href="#multi-dimensional-arrays"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>It’s possible in Go to generate arrays that have more than two dimensions :</p>
<pre v-highlightjs><code class="go" v-pre >// array/two-dimensional/main.go
package main

import &quot;fmt&quot;

func main() {
    threeD := [2][2][2]string{}
    threeD[0][0][0] = &quot;element 0,0,0&quot;
    threeD[0][1][0] = &quot;element 0,1,0&quot;
    threeD[0][0][1] = &quot;element 0,1,1&quot;
    fmt.Println(threeD)
}</code></pre>
<p>The array <span v-highlightjs><code class="go" v-pre style="display: inline">3d</code></span> has three dimensions. On figure <a href="#fig:3-dimensional-array" data-reference-type="ref" data-reference="fig:3-dimensional-array">6</a> you can visualize its shape.</p>
<figure>
<b-img :src="require('@/assets/images/3d_array.png')" alt="3 dimensional array[fig:3-dimensional-array]"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">3 dimensional array<span id="fig:3-dimensional-array" label="fig:3-dimensional-array">[fig:3-dimensional-array]</span></figcaption>
</figure>
<div id="caution" class="anchor"></div>
<h4 data-number="16.0.0.1"><span class="header-section-number">16.0.0.1</span> Caution <a href="#caution"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h4>
<p>Arrays that have more than two dimensions are hard to visualize. If you want to introduce such a type of array in your code, maybe you should consider another solution. Your code will be harder to read and harder to understand and review.</p>
<div id="efficiency-consideration" class="anchor"></div>
<h1 data-number="17"><span class="header-section-number">17</span> Efficiency consideration <a href="#efficiency-consideration"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<ul>
<li><p>Accessing data on an array with its index is a <strong>very fast</strong> operation.</p></li>
<li><p>Finding that an element is inside an array can be slow because you have to iterate over it. If you have no clue about the order of the elements stored in your array you will probably need to iterate over all the array. In this case, you can maybe use a map (see the dedicated chapter).</p></li>
</ul>
<div id="limitations" class="anchor"></div>
<h1 data-number="18"><span class="header-section-number">18</span> Limitations <a href="#limitations"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>Arrays are efficient, but their main limitation is their fixed size.</p>
<p>In a real-world situation, data that you want to store rarely have a size fixed at compile time. Therefore the Go creators have created the notion of slices. We will see how to manipulate them in a dedicated chapter.</p>
<div id="test-yourself" class="anchor"></div>
<h1 data-number="19"><span class="header-section-number">19</span> Test yourself <a href="#test-yourself"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<div id="questions" class="anchor"></div>
<h2 data-number="19.1"><span class="header-section-number">19.1</span> Questions <a href="#questions"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<ol type="1">
<li><p>An array is a collection of elements with various types. True or False?</p></li>
<li><p>An array is passed as a parameter to a function; can the function modify the array?</p></li>
<li><p>What is the length of an array?</p></li>
<li><p>What is the relation between length and capacity (for arrays)?</p></li>
</ol>
<div id="answers" class="anchor"></div>
<h2 data-number="19.2"><span class="header-section-number">19.2</span> Answers <a href="#answers"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<ol type="1">
<li><p>An array is a collection of elements with various types. True or False?</p>
<ol type="1">
<li><p>False</p></li>
<li><p>The elements of an array should have the same type.</p></li>
</ol></li>
<li><p>An array is passed as a parameter to a function; can the function modify the array?</p>
<ol type="1">
<li><p>No</p></li>
<li><p>Hiwever if you pass a pointer to an array to the function, it can modify it.</p></li>
</ol></li>
<li><p>What is the length of an array?</p>
<ol type="1">
<li>The maximum number of elements that can be stored</li>
</ol></li>
<li><p>What is the relation between length and capacity (for arrays)?</p>
<ol type="1">
<li>For arrays length = capacity.</li>
</ol></li>
</ol>
<div id="key-takeaways" class="anchor"></div>
<BuyCopyInvite></BuyCopyInvite>
<h1 data-number="20"><span class="header-section-number">20</span> Key Takeaways <a href="#key-takeaways"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<ul>
<li><p>An array is a collection of elements of the <strong>same type</strong></p></li>
<li><p>The size of an array is called the <strong>length.</strong></p></li>
<li><p>The length of an array is <strong>known at compile time.</strong></p></li>
<li><p>In other words, an array once created cannot grow</p></li>
<li><p>To iterate over an array, you can use a for loop (with or without a range clause)</p></li>
<li><p>When an array is passed to a function, it is <strong>copied</strong>, the function cannot modify it</p></li>
<li><p>When you pass to a function a <strong>pointer</strong> to an array, you make the function able to <strong>modify</strong> the array.</p></li>
<li><p>There is no built-in function to find an element in an array.</p></li>
</ul>

                    <!-- END CONTENT -->
                    <!-- Bibliography -->
                    <h1>Bibliography</h1>
                    <ChapterBibliography chapter-id="Chap20Arrays"></ChapterBibliography>
					<!-- Next / Previous -->
					<b-row class="ml-1 mr-1 ">
						
							<b-col class="text-center border mr-1 p-2" >
								<router-link :to="{name:'Chap19UnitTests'}">
									<p><u><small>Previous</small></u></p>
									<p><small>Unit Tests</small></p>
								</router-link>
							</b-col>
						
						
							<b-col class="text-center border p-1 ">
								<router-link :to="{name:'Chap21Slices'}">
									<p><u><small>Next</small></u></p>
									<p><small>Slices</small></p>
								</router-link>
							</b-col>
						
					</b-row>
					<b-row class="mt-1 ml-1 mr-1">
						<b-col class="text-center border p-1 ">
							<b-link :to="{name:'Home'}" >Table of contents</b-link>
						</b-col>
					</b-row>
          			<FeedbackInvite></FeedbackInvite>
					<NewsletterInput></NewsletterInput>
					<Footer></Footer>
                </b-col>
				<b-col ></b-col>
            </b-row>
        </div>
    </div>
</template>

<script>
import TOCChapter from "@/components/toc/TocChapter";
import ChapterBibliography from "@/components/ChapterBibliography";
import NavBar from "@/components/NavBar";
import { BIconLink45deg } from 'bootstrap-vue'
import Footer from "@/components/Footer";
import ChapterHeading from "@/components/ChapterHeading";
import BuyCopyInvite from "@/components/BuyCopyInvite";
import NewsletterInput from "@/components/NewsletterInput";
import FeedbackInvite from "@/components/FeedbackInvite";

const title = "Arrays - Practical Go Lessons"
const description = "An array is a collection of elements of the same type. This collection has a fixed number of elements known at compile time. We will learn how to create an use arrays"

export default {
  name: "Chap20Arrays",
  components: {FeedbackInvite,BuyCopyInvite,NewsletterInput,ChapterHeading, ChapterBibliography,TOCChapter,NavBar,BIconLink45deg, Footer},

  created() {
    window.scrollTo(0,0);
  },data () {return {publicPath: process.env.BASE_URL}},
  metaInfo: {
      title: title,
      htmlAttrs: {
        lang: 'en',
      },
      meta: [
        { charset: 'utf-8' },
        { name: 'description', content: description },
        { name: 'robots', content: "index, follow" },
        { property: 'og:locale', content: process.env.VUE_APP_SITE_LOCALE_META },
        { property: 'og:type', content: "website" },
        { property: 'og:title', content: title},
        { property: 'og:description', content: description },
        { property: 'og:url', content: window.location.href },
        { property: 'og:site_name', content: 'Practical Go Lessons' },
        { property: 'twitter:card', content: "summary_large_image" },
        { property: 'twitter:creator', content: process.env.VUE_APP_TWITTER_USERNAME }
      ],
      link : [
        { rel : "canonical", href : window.location.href}
      ]

    }
}
</script>

<style scoped>

</style>
